@import "../../shared/sass/vars";

.admin-table {
  min-width: 400px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-spacing: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  border-collapse: unset;
  &-row {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  &-title {
    margin-top: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 17px;
    color: #006ef4; }
  td,
  th {
    padding: 15px 7px;
    text-align: left;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-child {
      width: 25px;
      padding-right: 8px;
      padding-left: 15px;
      text-align: center; } }
  td {
    font-weight: 700;
    color: #777777;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } }
  th {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid #e8e8e8;
    &:first-child {
      border-left: 15px solid transparent;
      padding-left: 0; }
    &:last-child {
      border-right: 15px solid transparent;
      padding-right: 0; }
    .admin-table-light {
      font-weight: 300; } }
  &-blue {
    background-color: #deecfe; }
  &-light-blue {
    background-color: #f7f7f5; }
  &-arrow {
    margin-left: 8px;
    width: 10px; }
  &-normal {
    td,
    th {
      max-width: none; } } }

.admin-content-item {
  margin-bottom: 40px;
  @media (max-width: 1199px) {
    width: 100%; }
  @media (max-width: 767px) {
    overflow-x: scroll; } }

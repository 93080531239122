@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300i,400,700,800');

@font-face {
  font-family: "Muller";
  src: url("../fonts/MullerBold.otf") format("opentype");
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: "Muller";
  src: url("../fonts/MullerMedium.otf") format("opentype");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ProximaNova-Regular.ttf') format('truetype'); }

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/ProximaNova-Semibold.ttf') format('truetype'); }

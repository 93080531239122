$lato: "Lato", sans-serif;
$muller: "Muller", sans-serif;
$openSans: "Open Sans", sans-serif;
$proximaNova: "ProximaNova", sans-serif;

$button-blue: #1088ee;
$blue: #006ef4;
$border-gray: #e8e8e8;
$gray: #777777;
$btn-gray: #607586;

@import "../../shared/sass/vars";

.admin-top {
  display: flex; }

.admin-avatar {
  width: 80px;
  min-width: 80px;
  height: 80px;
  padding: 5px;
  margin-right: 20px;
  border-radius: 50%;
  border: 1px solid #006ef4;
  overflow: hidden; }

.admin-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%; }

.admin-info {
  display: flex;
  flex-direction: column; }

.admin-name {
  margin: 4px 0 3px;
  font-family: $openSans;
  font-weight: bold;
  font-size: 15px; }

.admin-city {
  font-family: $openSans;
  font-style: italic;
  font-weight: 300;
  font-size: 12px; }

.admin-icon {
  margin-right: 10px; }

.admin-icon-right {
  margin-left: 10px; }

.admin-money {
  margin-top: 13px;
  font-family: $muller;
  font-weight: 700;
  font-size: 14px;
  color: #006ef4; }

.has-icon {
  display: flex;
  align-items: center; }

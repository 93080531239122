.send-sms {
	display: block; }

.send-sms-radio-btn {
  display: flex;
  align-items: center;
  & label {
    display: flex;
    align-items: center;
    margin-right: 5px;
    & input {
      width: 12px;
      height: 12px;
      margin-right: 5px; } } }

.from-select2 {
  margin-top: 10px;
  margin-bottom: 20px;
  & .select2-container {
    width: 100% !important;
    margin-left: 0 !important;
    & .select2-selection--single {
      justify-content: flex-start;
      width: 100% !important; } } }

.send-textarea {
  margin-bottom: -5px; }

.send-date-block {
  margin-bottom: 20px;
  padding: 5px 7px;
  border: 1px solid #ced0da;
  border-radius: 5px; }

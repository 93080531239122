@import "../../shared/sass/vars";

.admin-sidebar {
  margin-top: 27px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.1);
  border: 1px solid #e8e8e8;
  &-section {
    min-width: 215px;
    margin-right: 20px;
    @media (max-width: 800px) {
      width: 100%;
      margin-bottom: 40px;
      margin-right: 0; } }
  &-list {
    margin: 0;
    padding: 0;
    list-style: none; }
  &-item {
    a {
      display: flex;
      align-items: center;
      padding: 10px 0;
      font-family: $openSans;
      color: inherit;
      text-decoration: none;
      &:hover {
        color: $blue; } }
    &-active a {
      padding-left: 15px;
      font-weight: 800; } } }

.admin-icon-fix-width {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; }

.message-number {
  position: absolute;
  bottom: 0;
  right: 5px;
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  font-family: $muller;
  font-weight: 500;
  font-size: 9px;
  color: #fff;
  background-color: $blue;
  border: 1px solid #fff;
  border-radius: 50%; }

@import "vars";

.btn {
  min-width: 80px;
  padding: 6px 15px;
  font-size: 10px;
  font-family: $openSans;
  font-weight: 700;
  border-radius: 15px / 50%;
  transition: all 0.3s ease;
  &-table {
    margin-top: -10px;
    margin-bottom: -10px; }
  &-blue {
    color: #ffffff;
    background-color: $button-blue;
    border: 1px solid $button-blue;
    &:hover {
      color: #000000;
      background-color: #fff; } }
  &-white {
    color: #000000;
    background-color: #fff;
    border: 1px solid $button-blue;
    &:hover {
      color: #ffffff;
      background-color: $button-blue; } }
  &-gray {
    color: #ffffff;
    background-color: $gray;
    border: 1px solid $gray;
    &:hover {
      color: #000;
      background-color: #ffffff; } }
  &-big {
    min-width: 100px;
    font-size: 13px; } }

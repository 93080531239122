@import "vars";

body {
  margin: 0;
  background-color: #f1f1f1; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

img {
  max-width: 100%;
  width: auto;
  height: auto; }

.width100 {
  width: 100%; }

.input-block {
  margin-top: 10px;
  margin-bottom: 20px; }

input {
  padding: 0 35px 0 15px;
  width: 245px;
  height: 35px;
  font-family: 'ProximaNova', sans-serif;
  font-weight: 800;
  font-size: 14px;
  color: #354052;
  border: 1px solid #ced0da;
  border-radius: 5px;
  outline: none; }

textarea {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 15px;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  border: 1px solid #ced0da;
  border-radius: 5px;
  resize: none;
  outline: none; }

input:focus, input:hover, textarea:focus, textarea:hover {
  border: 1px solid #2ea2f8 !important; }

.input-active {
  position: relative;
  & input {
    border: 1px solid #1bb934 !important; }
  &:after {
    content: '';
    position: absolute;
    top: 12px;
    right: 12px;
    width: 11px;
    height: 9px;
    background: url("../images/checkmark.svg") no-repeat 50% 50%; } }

.admin {
  padding: 25px 0;
  font-family: $lato;
  font-size: 13px;
  background-color: #f1f1f1;
  &-main {
    display: flex;
    @media (max-width: 800px) {
      flex-wrap: wrap; } } }

.admin-content {
  max-width: 100%;
  flex-grow: 1; }

.c-black {
  color: #282828 !important; }

.select-active {
  position: relative;
  border: 1px solid #1bb934 !important;
  &:after {
    content: '';
    position: absolute;
    top: 12px;
    right: 12px;
    width: 11px;
    height: 9px;
    background: url("../images/checkmark.svg") no-repeat 50% 50%; } }

.select2-container--default .select2-selection--multiple {
  border: 1px solid #ced0da; }

.select-multiple {
  & .select2-container--default .select2-selection--multiple {
    width: 380px;
    height: 70px;
    @media (max-width: 450px) {
      width: auto; } }
  & .select2-container {
    margin-top: 10px;
    margin-bottom: 20px; }
  & .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 3px; }
  & .select2-container--default .select2-selection--multiple .select2-selection__choice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    min-width: 90px;
    height: 30px;
    margin-top: 3px;
    margin-right: 3px; }
  & .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 10px;
    font-size: 17px; }
  & .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #2ea2f8;
    outline: none; } }

.select-companies {
  & .select2-container--default .select2-selection--multiple {
    width: auto;
    min-height: 135px; }
  & .select2-container {
    width: 100%!important; }
  & .select2-container--default .select2-selection--multiple .select2-selection__choice {
    height: 15px;
    font-family: 'ProximaNova', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #354052;
    border: none;
    background: none; }
  & .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 135px;
    padding: 13px 6px; } }
